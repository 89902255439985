//トップページ
#home{
	background: url(../images/bg_top.jpg) top center no-repeat;background-size:cover;
	
	#wrapper{
		height: 100vh;min-height:800px;position: relative;
		&:before{display: none;}
	}
	header{
		width: 100%;position: absolute;z-index: 5;top:0;left:0;
	}
	footer{
		width: 100%;position: absolute;z-index: 4;bottom:0;left:0;
	}
	
}
.top-title-box{
	position: absolute;bottom:340px;left:11%;z-index: 3;
}
.top-title{margin-bottom: 5px;}
.top-catch{
	@extend .mincho;font-size: 2.6rem;letter-spacing: 0.1em;line-height: 1.3;
	text-shadow: $NAVY 0 0 5px;
}
.top-image{
	position: absolute;bottom:40px;right:-8%;z-index: 2;
	width: 68.75%;max-width:1100px;min-width: 800px;
	img{@include max(w);}
}