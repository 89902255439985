//layout

// ラッパー
#wrapper{
	width: 100%;min-width: 1000px;overflow: hidden;position: relative;
	&:before{
		content:"";width: 100%;max-width: 1600px;padding-bottom: 94%;
		position: absolute;top:0;right:0;z-index: 1;
		background:  url(../images/bg_contents01.png) right top no-repeat ;background-size:100%;
	}
}

// ヘッダー
header{
	width: 100%;display: flex;position: relative;z-index: 999;
}
.logo{
	width: 295px;margin-left: 45px;margin-top: 15px;
}

// グローバルナビ
.navi-wrap{

}
#menu{	
}
#gnavi{
	width: calc(100% - 340px);@extend .mincho;position: absolute;right:10px;top:20px;
	.gnavi-list{
		display: flex;justify-content: flex-end;
	}
	.gnavi-item{
		margin: 0 20px;
	}
	a{color: #fff;display: block;padding: 15px 10px;position: relative;font-size: 1.6rem;line-height: 1;
		transition:color $hover;
		&:before{
			content:"";width: calc(100% - 20px);height: 2px;background: $SKY;transition:opacity $hover,bottom 0.3s $ease9;
			position: absolute;bottom:10px;left:10px;
			opacity: 0;
		}
	}
	.active a,a:hover{color: $SKY;text-decoration: none;
		&:before{
			bottom:0;opacity: 1;
		}
	}
}
.sp-fnavi{display: none;}

article{
	width: 100%;
}



// フッター
footer{
	width: 100%;background: $GRAY;position: relative;z-index: 998;
}
.foot-box{
	display: flex;background: #fff;padding: 50px 40px;align-items: center;
	.foot-address{
		width: 55%;display: flex;align-items: center;
	}
	.foot-logo{
		width: 225px;margin-right: 50px;
	}
	.foot-address-text{
		font-size: 1.2rem;line-height: 1.5;color: $FONT_COLOR;
		width: calc(100% - 275px);
	}
	.foot-link{
		width: 45%;text-align: center;line-height: 1;
		li{display: inline-block;border-left: 1px solid $BDC;
			&:first-child{border: none;}
		}
		a{display: block;padding: 5px 18px;color: $NAVY;}
	}
}
.foot-bottom{
	display: flex;padding: 13px 35px;
}
.fnavi{
	display: flex;width: 70%;font-size: 1.2rem;
	li{margin-right: 25px;}
	a{color: #fff;}
}
.copyright{
	width: 30%;color: #fff;font-size: 1.2rem;letter-spacing: 0.05em;text-align: right;
	@extend .serif;
}
.pagetop{display: none;}