//mobile
body{
	font-size: 1.4rem;
	
}
input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea,select{font-size: 1.4rem;}
input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea{
padding:4px;	
}
form{
	.btn-submit{font-size: 1.6rem;width: 80%;padding: 18px 0;}
	.btn-back{font-size: 1.3rem;width: 60%;padding: 12px 0;}
}
p img,figure img{@include max(w);}


.spdisp{display: block;}
.sphide{display: none;}

.xs{font-size: 1.0rem;}
.s{font-size: 1.2rem;}
.l{font-size: 1.6rem;}

table{
	&:not(.nobreak){
		tr,th,td{display: block;border: none;padding: 0;width: 100%;margin-top: 0;}
		tr{border-bottom: 1px solid $BDC;padding-bottom: 16px;margin-bottom: 16px;}
		tbody{
			th{width: 100%;font-weight: bold;font-size: 1.2rem;margin-bottom: 5px;}
		}
	}
}

#wrapper{
	&:before{
		width: 600px;height: 570px;
	}
}
.logo{
	width: 50%;
	img{@include max(w);}
}

#menu-btn{
	width: 50px;height: 50px;top:10px;
	.menu-line{
		width: 24px;left:calc(50% - 12px);
		&:nth-child(1){top:18px;}
		&:nth-child(2){top:25px;}
		&:nth-child(3){top:32px;}
	}
}
#gnavi{
	.gnavi-item{margin-bottom: 20px;}
	a{font-size: 1.6rem;}
}
.navi-wrap{
	padding: 40px 0;
}
#menu.open{
	#menu-btn{
		.menu-line{
			&:nth-child(1){top:25px;}
			&:nth-child(3){top:25px;}
		}
	}
	.navi-wrap{visibility: visible;opacity: 1;}
}



.foot-box{
	padding: 40px 0;
	.foot-logo{
		img{width: 160px;height: auto;}
	}
	.foot-address-text{font-size: 1rem;}
	.foot-link{
		font-size: 1.1rem;
	}
}

.sp-fnavi{
	font-size: 1.2rem;
}
.foot-bottom{
	padding: 7px;
}
.copyright{
	font-size: 1rem;
}

.page-title-wrap{
	padding: 50px 0;
	.page-title-en{
		img{height: 60px;width: auto;}
	}
	.page-title-jp{
		font-size: 1.8rem;
	}
	&.page-title-small{
		padding: 50px 0 20px;
		.page-title-jp{font-size: 2.8rem;}
	}
}
.page-body{
	h2{font-size: 2.6rem;text-align: center;margin-bottom: 50px;}
	h3{font-size: 1.9rem;}
	h4{font-size: 1.7rem;}
}

.block{
	padding: 60px 0;
}

.bg-block{
	.block{padding: 60px 0;}
}

.anchor{
	&-list{
		font-size: 1.2rem;
		li{width: 33.33%;
			&:first-child{border: none;}
		}
		a{padding: 15px 0 30px;}
	}
}

.breadcrumb{
	padding: 30px 0;
	ul{
		font-size: 1rem;padding: 3px 20px;
	}
	li:before{
		margin: 0 5px 0 2px;
	}
}

#home{
	#wrapper{
		min-height:500px;
	}
}
.top-title-box{
	top:150px;
}
.top-title{
	img{width: 300px;}
}
.top-catch{
	font-size: 1.8rem;
}
.top-image{
	width: 110%;min-width: 0;max-width: auto;bottom:30px;
}

#service{
	.anchor{
		&-list{
			li{width: 50%;}
		}
	}
}

#achievement{
	.anchor{
		&-list{
			li{width: 50%;}
			a{padding: 10px 0 25px;}
		}
	}
	#asset{
		.block{
			padding-bottom: 100px;
		}
	}
	#corporate{
		&:before{
			width: 600px;height: 420px;
		}
	}
	
	.asset-box,.mediation-box{
		width: 48%;margin-bottom: 30px;
	}
	.asset-tb,.mediation-tb{
		display: block;
		dt{width: 100%;border-bottom: none;padding-bottom: 0;font-weight: bold;}
		dd{width: 100%;font-size: 1.2rem;margin-top: 0;}
	}
	.financial-box,.corporate-box{
		padding: 0 25px 25px;
	}
	.financial-title,.corporate-title{
		font-size: 1.8rem;
	}
}


#aboutus{
	#about01{
		.block{
			padding-bottom: 100px;
		}
	}
	#about03{
		&:before{
			width: 600px;height: 420px;
		}

	}

	.message{
		display: block;
		&-image{
			width: 100%;text-align: center;
			img{width:70%;display: inline-block;}
		}
		&-text{
			margin-top: 2em;width: 100%;
		}
		&-sign{
			font-size: 1.3rem;
			img{width: 100px;height: auto;margin-left: 10px;}
		}
	}
	.career{
		&-tb{margin-top: 2em;
			dt{
				width: 60px;
			}
			dd{
				padding-left: 60px;
			}
		}
	}
	
	.gmap{
		height: 0;padding-bottom: 100%;
	}
	.station{
		&-tb{
			display: flex;flex-wrap: wrap;margin-top: 2em;align-items:center;
			dt{width: 80%;float: none;
				&:before{width: 100% !important;}
			}
			dd{width: 20%;padding-left: 0;text-align: center;font-size: 1.2rem;}
			
		}
		&-name{
			background: $NAVY;position: relative;z-index: 2;display: inline-block;padding-right: 15px;
		}
		&-minute{margin: 0;font-size: 1.5rem;}
	}
}
#service{
	#service01{
		.block{
			padding-bottom: 100px;
		}
	}
	.service-image{
		width: 110%;left:-5%;
	}
	.service-list{
		margin-top: 0px;
	}
	.service-box{
		width: 100%;margin-top:30px;padding: calc(38% + 40px) 6% 140px;
		&:before{display: none;}
		&:first-child{margin-top: 0;}
	}
	.service-list-title{
		font-size: 1.8rem;height: auto;padding-bottom: 12px;
	}
	.service-list-detail{
		font-size: 1.4rem;
	}
	.service4{
		.service-list-title{line-height: 1;}
	}
}

#contact{
	.contact-tb{
		tbody{
			th{padding: 0;}
		}
		&.confirm{
			tbody{
				tr{border-bottom: 1px solid rgba(#fff,0.5);}
				th,td{border: none;}
			}
		}
	}
	.contact-privacy{
		margin-top: 0;
	}
	.btn-area{
		margin-top: 30px;flex-direction: column-reverse;
		.btn{margin: 2em auto 0;}
	}
	.thanks-link{
		text-align: center;margin-top: 3em;
	}
}
#policy,#sitemap{
	.page-body{
		h2{font-size: 2.6rem;}
	}
}
#privacy{
	.page-body{
		h2{font-size: 2.0rem;margin-bottom: 30px;}
		h3{font-size: 1.7rem;}
	}
}

#sitemap{
	.sitemap-list{
		margin-bottom: 60px;
		font-size: 1.5rem;
		.sub-link{font-size: 1.2rem;}
		.sub-link1{margin-top: 40px;}
	}
}
