//page

@import "contents/top";


#achievement{
	.anchor{
		&-list{
			li:nth-child(4) a{padding-top: 18px;}
		}
	}
	#asset{
		position: relative;
		&:before{
			content:"";width: 100%;height: 0;padding-bottom: 62.5%;
			background: url(../images/bg_contents02.png) 0 0 no-repeat;background-size:cover;
			position: absolute;bottom:0;left:0;z-index: 1;
		}
		.block{
			padding-bottom: 160px;
		}
	}
	#corporate{
		position: relative;
		&:before{
			content:"";width: 100%;max-width:1600px;height: 0;padding-bottom: 68.75%;
			background: url(../images/bg_contents03.png) 0 0 no-repeat;background-size:cover;
			position: absolute;top:0;right:0;z-index: 1;
		}

	}
	#financial,#mediation{
		background: $BGC_BLUE;color: $FONT_COLOR;
		h2,h3{color: $BLACK;}
	}

	
	.asset-list{
		display: flex;justify-content: space-between;flex-wrap: wrap;
	}
	
	.asset-box{
		width: 240px;margin-top: 0;margin-bottom: 50px;
	}
	.asset-tb{
		display: flex;flex-wrap: wrap;font-size: 1.2rem;line-height: 1.5;margin-top: 0;
		dt{width: 90px;margin-top: 7px;border-bottom: 1px solid rgba(#fff,0.5);padding-bottom: 7px;}
		dd{width: calc(100% - 90px);margin-top: 7px;border-bottom: 1px solid rgba(#fff,0.5);padding-bottom: 7px;}
	}
	.asset-text{
		margin-top: 10px;font-size: 1.2rem;line-height: 1.7;
	}
	
	.mediation-list{
		display: flex;justify-content: space-between;flex-wrap: wrap;
	}
	
	.mediation-box{
		width: 240px;margin-top: 0;margin-bottom: 50px;
	}
	.mediation-tb{
		display: flex;flex-wrap: wrap;font-size: 1.2rem;line-height: 1.5;margin-top: 0;
		dt{width: 115px;margin-top: 7px;border-bottom: 1px solid $BDC;padding-bottom: 7px;}
		dd{width: calc(100% - 115px);margin-top: 7px;border-bottom: 1px solid $BDC;padding-bottom: 7px;}
		dt:nth-of-type(2),dd:nth-of-type(2){border: none;}
	}
	
	.financial-box{
		border: 1px solid $NAVY;background: #fff;padding: 0 35px 30px;margin-top: 55px;
		&:first-child{margin-top: 0;}
		&.f1{
			.financial-list{display: flex;flex-wrap: wrap;
				li{width: 50%;}
			}
		}
	}
	.financial-title{
		position: relative;width: calc(100% + 20px);left:-10px;top:-18px;padding: 5px 10px;
		background: linear-gradient(90deg,$NAVY,$BLUE);color: #fff !important;font-size: 2.1rem;
	}
	.financial-text{
		font-weight: bold;color: $NAVY;margin-top: 0;
	}
	.financial-stit{
		font-weight: bold;color: $BLACK;font-size: 1.6rem;line-height: 1;margin-top: 15px;
		font-family: $FONT_FAMILY;
	}
	.financial-list{
		margin-top: 7px;font-size: 1.3rem;
		li{
			margin-top: 5px;padding-left: 14px;
			background: url(../../achievement/images/icon_achieve_list.png) 1px 8px no-repeat;background-size:8px;
		}
	}
	
	
	.corporate-box{
		border: 1px solid $SKY;padding: 0 35px 30px;position: relative;margin-top: 55px;
		&:first-child{margin-top: 0;}
	}
	.corporate-title{
		position: relative;width: calc(100% + 20px);left:-10px;top:-18px;padding: 5px 10px;
		background: linear-gradient(90deg,#bfe4f5,#cae0f7);color: $NAVY !important;font-size: 2.1rem;
	}
	.corporate-text{
		font-weight: bold;color: $SKY;margin-top: 0;
	}
	.corporate-stit{
		font-weight: bold;font-size: 1.6rem;line-height: 1;margin-top: 15px;
		font-family: $FONT_FAMILY;
	}
	.corporate-list{
		margin-top: 7px;font-size: 1.3rem;display: flex;flex-wrap: wrap;
		li{
			width: 50%;margin-top: 5px;padding-left: 14px;
			background: url(../../achievement/images/icon_achieve_list_w.png) 1px 8px no-repeat;background-size:8px;
		}
	}
	
	.breadcrumb{
		background: $BGC_BLUE;color: $FONT_COLOR;
		ul{border-color: $BDC;}
		li{
			&:before{color: $FONT_COLOR;}
		}
		a{color: $FONT_COLOR;}
	}
}

#aboutus{
	#about01{
		position: relative;
		&:before{
			content:"";width: 100%;height: 0;padding-bottom: 62.5%;
			background: url(../images/bg_contents02.png) 0 0 no-repeat;background-size:cover;
			position: absolute;bottom:0;left:0;z-index: 1;
		}
		.block{
			padding-bottom: 210px;
		}
	}
	#about02{
		background: $BGC_BLUE;color: $FONT_COLOR;
		h2{color: $BLACK;}
	}
	
	#about03{
		position: relative;
		&:before{
			content:"";width: 100%;max-width:1600px;height: 0;padding-bottom: 68.75%;
			background: url(../images/bg_contents03.png) 0 0 no-repeat;background-size:cover;
			position: absolute;top:0;right:0;z-index: 1;
		}

	}
	
	.message{
		display: flex;justify-content: space-between;
		&-image{
			width: 270px;
		}
		&-text{
			width: calc(100% - 320px);margin-top: 0;
			
		}
		&-sign-wrap{
			text-align: right;
		}
		&-sign{
			@extend .mincho;display: inline-block;border-bottom: 1px solid #fff;line-height: 1;padding-bottom: 5px;
			img{display: inline-block;margin-left: 20px;vertical-align: middle;margin-top: 0;}
		}
		&-post{display: inline-block;}
	}
	.career{
		display: flex;margin-top: 75px;
		&-title{
			width: 200px;
		}
		&-tb{
			width: calc(100% - 200px);border-left: 1px solid #fff;padding-left: 40px;margin-top: 0;
			* + *{margin-top:25px;}
			dt{width: 78px;float: left;font-size: 1.8rem;@extend .mincho;line-height: 1;padding-top: 3px;}
			dd{padding-left: 78px;font-size: 1.4rem;
				&:first-of-type{margin-top: 0;}
			}
		}
	}
	.gmap{
		height: 450px;position: relative;background: $GRAY;margin-top: 50px;
		iframe{
			width: 100%;height: 100%;position: absolute;left:0;top:0;
		}
	}
	.gmap-link{
		margin-top: 10px;text-align: right;font-size: 1.3rem;
		a{color: #fff;}
	}
	.station{
		margin-top: 55px;display: flex;
		&-title{
			width: 265px;
		}
		&-tb{
			width: calc(100% - 265px);margin-top: 0;
			* + *{margin-top:12px;}
			dt{width: 325px;float: left;position: relative;
				&:before{
					content:"";width: 100%;height: 1px;background: #8d8d8d;
					position: absolute;right:0;top:50%;z-index: 1;
				}
				&:nth-of-type(1):before{width: 55px;}
				&:nth-of-type(2):before{width: 100px;}
				&:nth-of-type(3):before{width: 165px;}
				&:nth-of-type(4):before{width: 210px;}
				&:nth-of-type(5):before{width: 130px;}
				&:nth-of-type(6):before{width: 225px;}
			}
			dd{padding-left: 340px;
				&:first-of-type{margin-top: 0;}
			}
		}
		
		&-minute{
			font-weight: bold;font-size: 1.8rem;line-height: 1;margin: 0 5px;
		}
	}
}

#service{
	#service01{
		position: relative;
		&:before{
			content:"";width: 100%;height: 0;padding-bottom: 62.5%;
			background: url(../images/bg_contents02.png) 0 0 no-repeat;background-size:cover;
			position: absolute;bottom:0;left:0;z-index: 1;
		}
		.block{
			padding-bottom: 210px;
		}
	}
	.service-image{
		width: 1000px;position: relative;left:-40px;margin-top: 60px;
		img{@include max(w);}
	}
	
	#service02{
		background: $BGC_BLUE;color: $FONT_COLOR;
		h2{color: $BLACK;}
	}
	.service-list{
		display: flex;width: 1200px;position: relative;left:-140px;margin-top: 80px;
	}
	.service-box{
		width: 300px;margin-top: 0;background: #fff;position: relative;padding: 150px 35px 140px;overflow: hidden;
		&:before{
			content:"";width: 1px;height: 100%;background: #94adc7;
			position: absolute;left:0;top:0;
		}
		&:first-child:before{display: none;}
	}
	.service-list-image{
		position: absolute;z-index: 2;top:0;left:0;
		img{@include max(w);}
	}
	.service-list-title{
		text-align: center;color: $BLACK;font-size: 2.4rem;margin-top: 0;height: 70px;border-bottom: 1px solid $BLACK;
	}
	.service-list-detail{
		margin-top: 30px;font-size: 1.6rem;
		li{
			padding-left: 19px;background: url(../images/icon_list.png) 0 10px no-repeat;background-size:8px;
			margin-top: 10px;@extend .mincho;margin-top: 5px;
			&.etc{background: none;}
		}
	}
	.service-line{
		width: 4px;height: 50px;background: $SKY;
		position: absolute;bottom:-25px;left:calc(50% - 2px);			
		&:before,&:after{
			content:"";width: 4px;height: 30px;background: $SKY;
			position: absolute;top:10px;
		}
		&:before{left:-10px;}
		&:after{right:-10px;}
	}
	.service-list-btn{
		position: absolute;bottom:50px;left:35px;width: calc(100% - 70px);
		.btn{border-color: $BLACK;color: $BLACK;width: 100%;border-radius: 0;padding: 10px 0;font-size: 1.5rem;line-height: 1.3;
			&:hover{
				border-color: $SKY;color: $SKY;
			}
			.s{letter-spacing: 0;}
		}
	}
	.service4{
		.service-list-title{line-height: 70px;}
	}
	.breadcrumb{
		background: $BGC_BLUE;color: $FONT_COLOR;
		ul{border-color: $BDC;}
		li{
			&:before{color: $FONT_COLOR;}
		}
		a{color: $FONT_COLOR;}
	}
}

#contact{
	
	.contact-tb{
		tr{border: none;}
		tbody{
			th{width: 180px;padding-top: 20px;}
		}
		&.confirm{
			th,td{border-bottom: 1px solid rgba(255,255,255,0.5);}
		}
	}
	.contact-privacy{
		text-align: center;
		label{
			display: inline-block;border-bottom: 1px solid rgba(#fff,0.5);padding: 5px 5px;
		}
	}
	.btn-area{
		display: flex;justify-content: center;margin-top: 60px;
		.btn{margin: 0 20px;}
	}
}

#policy,#privacy,#sitemap{
	.page-body{
		h2{font-size: 2.8rem;}
	}
	.block{
		padding-bottom: 30px;
	}
}

#sitemap{
	.sitemap-list{
		text-align: center;font-size: 	1.8rem;
		a{color: #fff;}
		.sub-link{
			font-size: 1.4rem;
		}
		.sub-link1{margin-top: 80px;}
	}
}
