@mixin fz($size: 24,$lh:$LINE_HEIGHT,$imp:0) {
	$base: 10;
	@if $imp==1{
		font-size: $size + px !important;  font-size: ($size / $base) * 1rem !important;
	}@else{
		font-size: $size + px;  font-size: ($size / $base) * 1rem;	
	}
	@if $lh !=$LINE_HEIGHT{
 		line-height:$lh;
 	}
}

@mixin center-box($w:0,$mb:0){
	width:$w;	margin:0 auto $mb;
}

@mixin last-mb0(){
	>:last-child{margin-bottom: 0;}
}

@mixin sprite($filename,$w,$h,$top){
	display: block;
	text-indent: -9999px;
	width: $w;
	height: $h;
	background:url(#{$img_common}#{$filename}) 0 #{-$top} no-repeat;
	&:hover{
		background-position: #{-$w} #{-$top};
	}
}
@mixin grad($start-color:#666, $end-color:#333, $orientation:to right){
  background-color:$start-color;
  background: linear-gradient($orientation,  $start-color 0%,$end-color 100%);
}

@mixin max($type){
	@if $type==w{
		width:100%; height:auto; 
	}@elseif $type==h{
		width:auto; height:100%; 
	}
}

@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}
