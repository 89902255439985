//base
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');


*,:before,:after{box-sizing:border-box; word-break: break-all;}

html{
	-webkit-text-size-adjust: none;
	font-size: 62.5%;
}

img{display: block;max-width: 100%;}
ol{
	list-style-position: inside;
}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:$FONT_SIZE;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;letter-spacing: 0.1em;
	font-feature-settings: "palt";	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
	background:$NAVY;color: #fff;
	
}

/* IE表示用のCSS　*/
body.ie{
  font-family: $IE_FF;
}

::selection {
	background: $GRAY;
	color: #fff;
}

.mincho{
	font-family:YakuHanMP,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,serif;
}
.serif{
	font-family: 'EB Garamond', serif;
}