//module
a{color:$SKY;text-decoration: none;
	&:hover{text-decoration: underline;
		img{opacity:0.7; transition: opacity $hover;}
	}
}
// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;}
.fl-right {	float: right;}
.cl{clear:both;}

.btn{
	@extend .mincho;display: inline-block;background:transparent;border: 1px solid #fff;border-radius:3px;
	padding: 15px 30px;text-align: center;line-height: 1;color: #fff;
	transition:color $hover,border $hover;
	&:hover{
		text-decoration: none;border-color: $SKY;color: $SKY;
	}
}

.arrow{

}

//フォントサイズ
.l{font-size:1.8rem;display: inline-block;}
.s{font-size:1.4rem;display: inline-block;}
.xs{font-size:1.2rem;display: inline-block;}

//フォントカラー
.red{	color: $RED;}


.tbdisp{display: none;}
.spdisp{display: none;}

.page-title-wrap{
	width: 1200px;margin: 0 auto;padding: 140px 0 70px;position: relative;z-index: 10;line-height: 1;
	.page-title-en{
		img{height: 93px;width: auto;}
	}
	.page-title-jp{
		@extend .mincho;font-size: 2.4rem;letter-spacing: 0.1em;
	}
	&.page-title-small{
		padding: 100px 0;
		.page-title-jp{font-size: 3.8rem;}
	}
	
}
.page-body{
	width: 100%;position: relative;z-index: 10;
	h2,h3,h4,h5,h6{line-height: 1.2;}
	h2{
		font-size: 3.8rem;@extend .mincho;
	}
	h3{
		font-size: 2.4rem;@extend .mincho;
	}
	h4{
		font-size: 2.1rem;@extend .mincho;
	}
	h5{
		font-size: 1.8rem;
	}
	h6{
	
	}
	* + *{margin-top:2em;}
	
	ul:not([class]){
		li{padding-left: 15px;position: relative;margin-top: 10px;
			&:before{content:"";width: 4px;height: 4px;display: block;background: $BLACK;
				position: absolute;left:2px;top:calc(0.9em - 2px);
			}
		}
	}
	ol:not([class]){
		li{margin-top: 10px;}
	}
}
.box{
	border: 1px solid $BDC;padding:1.5em;
	@include last-mb0();
	> * + *{margin-top:1em;}
}

.block{
	width: 920px;padding: 110px 0;margin: 0 auto;position: relative;z-index: 10;
	&:first-child{
		padding-top: 40px;
	}
}
.bg-block{
	width: 100%;margin-top: 0 !important;position: relative;z-index: 10;
	.block{
		padding: 110px 0;
	}
}

.anchor{
	position: relative;z-index: 10;text-align: center;@extend .mincho;
	&-list{
		display: flex;justify-content: center;flex-wrap: wrap; line-height: 1;font-size: 1.8rem;
		li{width: 215px;margin-top: 0 !important;display: flex;align-items: center;position: relative;
			&:before{
				content:"";height: 34px;width: 1px;background: rgba(#fff,0.5);
				position: absolute;left:0;top:calc(50% - 17px);
			}
			&:first-child:before{display: none;}
		}
		a{display: block;width: 100%;height: 100%;padding: 10px 0 35px;color: #fff;transition: color $hover;position: relative;
			&:before{
				content:"";width: 14px;height: 14px;background: url(../images/icon_anchor.png) center center no-repeat;background-size:cover;
				position: absolute;left:50%;bottom:8px;transform: translateX(-50%);
				transition:bottom 0.3s $ease9;
			}
			&:hover{
				color:$SKY;text-decoration: none;
				&:before{
					bottom:0;
				}
			}
		}
	}
}
//パンくずリスト
.breadcrumb-wrap{
	
}
.breadcrumb{
	text-align: center;padding: 90px 0 60px;
	ul{
		display: inline-block;	padding: 3px 45px;border-left: 1px solid #fff;border-right: 1px solid #fff;font-size:1.2rem;margin: 0 auto;
	}
	li{display: inline-block;line-height: 1;
		&:before{content:">";display: inline-block;margin: 0 8px 0 5px;color: $BDC;}
		&:first-child:before{display: none;}
	}
	a{color: inherit;}
}

//表組み
table{width: 100%;font-size: $FONT_SIZE;line-height: 1.5;}
th,td{padding:15px 0;vertical-align: top;}

thead th{	font-weight: bold; text-align: center;}
tbody{
	tr{
		border-top: 1px solid $BDC;
		&:first-of-type{border: none;}
	}
	th{ width: 14%;}
}

//罫線
hr{	border: none;border-bottom: 5px double $BDC;padding-top:80px;margin-bottom: 80px;}

