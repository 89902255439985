//plugins

/*exValidation*/
//入力エリア下
.errMsg{padding-bottom:1px; display:block; line-height:1.4;margin-top: 0 !important;}
.formError{
padding-top:5px;display:block;margin-top: 5px !important;
	.formErrorContent{
		display: inline-block;padding:3px 8px; width:auto; position:relative; color:#fff; background:$RED; font-size:1.2rem; _z-index:1; 
	}
	.formErrorArrow{display: none;}
	.fadeOut{opacity: 0.2;}
}
.err{border-color:$RED !important;}