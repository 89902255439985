//small pc
@media only screen and (max-width: $BREAK_PC){
	#gnavi{
		.gnavi-item{
			margin: 0 15px;
		}
	}
	
	.page-title-wrap{
		width: 960px;
	}
	.foot-box{
		.foot-address{display: block;}
		.foot-address-text{
			width: 100%;margin-top: 5px;
		}
		.foot-link{
			a{padding: 5px 10px;}
		}
	}
	
	#service{
		.service-image{
			width: 960px;left:0;
		}
		
		.service-list{
			width: 960px;left:-20px;flex-wrap: wrap;justify-content: space-between;
		}
		.service-box{
			width: 50%;padding: calc(19% + 35px) 70px 140px;
			&:nth-child(3):before{display: none;}
		}
		
	}
	
}