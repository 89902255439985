//tablet
.tbdisp{display: block;}
.tbhide{display: none;}

body{
	
	
}

#wrapper{
	min-width: 0;
	&:before{
		width: 900px;height: 850px;padding-bottom: 0;
	}
}

.logo{
	margin-left: 4%;
}

#menu{

}
#menu-btn{
	display: block;width: 60px;height: 60px;
	position: fixed;right:10px;top:20px;cursor: pointer;z-index: 999;overflow: hidden;
	.menu-line{display: block;width: 28px;height: 1px;
		background: #fff;position: absolute;left:calc(50% - 14px);
		&:nth-child(1){top:22px;transition: top 0.1s $ease9 0.2s,transform 0.2s $ease9 0.05s;}
		&:nth-child(2){top:30px;transition: transform 0.1s $ease9 0s;}
		&:nth-child(3){top:38px;transition: top 0.1s $ease9 0.2s,transform 0.2s $ease9 0.05s;}
	}
}
.navi-wrap{
	display: block;visibility: hidden;opacity: 0;transition: all 0.6s $ease7;
	width: 100%;height: 100%;padding: 80px 0;background: rgba($NAVY,0.7);backdrop-filter:blur(5px);
	overflow: auto;	-webkit-overflow-scrolling:touch;
	position: fixed;z-index: 998;top:0;left:0;
}
#gnavi{
	position: relative;z-index: 1;	padding: 0 8%;width: 100%;
	.gnavi-list{display: block;text-align: center;}
	.gnavi-item{margin-bottom: 30px;width: 100%;}
	a{font-size: 1.8rem;display: inline-block;}
}
#menu.open{
	#menu-btn{
		.menu-line{
			&:nth-child(1){top:30px;transform: rotate(-135deg);transition: top 0.1s $ease9 0.05s,transform 0.2s $ease9 0.1s;}
			&:nth-child(2){transform: scaleX(0);}
			&:nth-child(3){top:30px;transform: rotate(135deg);transition: top 0.1s $ease9 0.05s,transform 0.2s $ease9 0.1s;}
		}
	}
	.navi-wrap{visibility: visible;opacity: 1;}
}
.sp-fnavi{
	display: block;padding-top: 40px;text-align: center;font-size: 1.3rem;
	li{margin-bottom: 10px;}
	a{color: #fff;}
}

.foot-box{
	display: block;text-align: center;
	
	.foot-address{
		width: 100%;
	}
	.foot-logo{
		width: 100%;margin-right: 0;
		img{display: inline-block;	}
	}
	.foot-link{
		width: 100%;font-size: 1.3rem;margin-top: 15px;
	}
}


.fnavi{display: none;}
.copyright{
	width: 100%;text-align: center;
}
.paginate{
	width: 100%;padding: 50px 4% 0;
}

.page-title-wrap{
	text-align: center;width: 100%;
	.page-title-en{
		display: block;
		img{display: inline-block;}
	}
	&.page-title-small{
		.page-title-jp{font-size: 3.2rem;}
	}
}

.page-body{
	h2{font-size: 3.2rem;}
}

.block{
	width: 86%;
}

.anchor{
	&-list{
		font-size: 1.6rem;
		li{width: 30%;}
	}
}
#home{
	#wrapper{
		min-height:600px;
	}
	
}
.top-title-box{
	text-align: center;left:0;bottom:auto;top:200px;width: 100%;
}
.top-title{
	img{width: 440px;height: auto;display: inline-block;}
}
.top-catch{
	font-size: 2.2rem;
}
.top-image{
	width: 100%;min-width: 400px;max-width: 800px;right:0;
}

#service{
	.anchor{
		&-list{
			li{width: 45%;}
		}
	}
}

#achievement{
	.anchor{
		&-list{font-size: 1.4rem;
			li{width: 24%;}
		}
	}
	#corporate{
		&:before{
			width: 900px;right:-5%;padding-bottom: 0;height: 620px;
		}
	}
	.asset-box,.mediation-box{
		width: 31%;
	}
	.asset-image,.mediation-image{
		img{@include max(w);}
	}
	.asset-tb{font-size: 1rem;
		dt{width: 70px;}
		dd{width: calc(100% - 70px);}
		
	}
	.mediation-tb{font-size: 1rem;
		dt{width: 90px;}
		dd{width: calc(100% - 90px);}
	}
	
	.financial-list,.corporate-list{
		display: block !important;
		li{width: 100% !important;}
	}
	
}



#aboutus{
	#about03{
		&:before{
			width: 900px;right:-5%;padding-bottom: 0;height: 620px;
		}
	}
	
	.message{
		&-image{
			width: 240px;
			img{@include max(w);}
		}
		&-text{
			width: calc(100% - 270px);
		}
	}
	
	.career{
		display: block;
		&-title{
			width: 100%;
		}
		&-tb{width: 100%;margin-top: 40px;padding-left: 0;border: none;}
	}
	
	.profile-tb{
		tbody th{
			width: 120px;
		}
	}
	.gmap{
		
	}
	.station{
		display: block;
		&-title{width: 100%;}
		&-tb{width: 100%;margin-top: 40px;}
	}
}
#service{
	
	.service-image{
		width: 100%;
	}
	.service-list{
		width: 100%;left:0;
	}
	.service-box{
		padding: calc(19% + 30px) 5% 140px;
	}
	.service-list-btn{
		left:5%;width: 90%;
	}
}
