//form
input,button,textarea,select{font-family:inherit;font-size: inherit;color: #fff;}
input[type="button"],input[type="submit"]{
	appearance: none;  margin: 0; cursor: pointer;
}
input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea{
	appearance: none; width:100%;	border: 1px solid rgba(#fff,0.5);	padding:8px; border-radius:0;	background: rgba(#fff,0.1);
	
}
input[type="radio"],input[type="checkbox"]{margin-right: 5px;}

.select-wrap{
	position: relative; display: inline-block;max-width: 100%;
	&:after{content:"›";color: $BLACK;position: absolute;right:8px;top:calc(50% - 0.5em);line-height: 1;
		transform: rotate(90deg);z-index: 1;font-size: 1.4rem;
	}
	&:before{
		content:"";width: 1px;height: 100%;position: absolute;right:25px;top:0;background: $BDC;
	}
}
select{
	margin: 0;max-width: 100%;
	border: 1px solid $BDC;appearance: none;border-radius: 0;
	padding: 3px 33px 3px 8px;background: transparent;position: relative;z-index: 2;
}
select::-ms-expand { display: none;}

:placeholder-shown {color: #bbb; }
::-webkit-input-placeholder {color:#bbb; }
::-moz-placeholder {color: #bbb; opacity: 1; }
:-ms-input-placeholder {color:#bbb; }

form{
	.input-box{display: inline-block;margin-top: 0;}
	#sei,#mei,#seikana,#meikana{width: 120px;}
	#sei,#seikana{margin-right:15px;}
	#postal-code{width: 120px;}
	#ckemail{margin-top: 10px;	}
	#tel,#fax{width: 180px;}
	#comment{height: 150px;}
	.btn-submit{
		display: block;font-size: 2.1rem;width: 400px;margin: 0 auto;padding: 25px 0;letter-spacing: 0.2em;
		transition: border 0.25s $ease5,color 0.25s $ease5,background 0.25s $ease5;
		&:hover{
			border-color: $SKY;color: $SKY;background: rgba(#fff,0.25);
		}
	}
	.btn-back{
		display: block;font-size: 1.6rem;width: 200px;margin: 0 auto;padding: 15px 0;letter-spacing: 0.2em;color: rgba(#fff,0.7);
		transition: border 0.25s $ease5,color 0.25s $ease5,background 0.25s $ease5;border-color: rgba(#fff,0.5);
		&:hover{
			border-color: $SKY;color: $SKY;background: rgba(#fff,0.25);
		}
	}
	
	#name.err,#furigana.err{
		border: none;
		input{border-color: $RED !important;}
	}
	
}
